import React from "react";

import { ImagesColumnWrapper } from "./ImagesColumn.styles";
import { ImagesColumnItem } from "./ImagesColumnItem";

interface ImagesColumnProps {
  imageNameOne: string;
  imageNameTwo: string;
  imageNameThree: string;
}

const ImagesColumn: Component<ImagesColumnProps> = ({
  children,
  imageNameOne,
  imageNameTwo,
  imageNameThree
}) => {
  return (
    <ImagesColumnWrapper>
      <ImagesColumnItem number={1} imageName={imageNameOne} />
      <ImagesColumnItem number={2} imageName={imageNameTwo} />
      <ImagesColumnItem number={3} imageName={imageNameThree} />
      {children}
    </ImagesColumnWrapper>
  );
};

export default ImagesColumn;
