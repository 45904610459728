import React, { useRef } from "react";

import Advantages, { AdvantagesType } from "components/common/Advantages/Advantages";
import type { HeaderGroupProps } from "components/common/headers/HeaderGroup/HeaderGroup";
import ImagesColumn from "components/common/ImagesColumn/ImagesColumn";
import PageTitle, { type PageTitleProps } from "components/common/other/PageTitle/PageTitle";
import About from "components/common/sections/About/About";
import CallToAction from "components/common/sections/CallToAction/CallToAction";
import type { Service } from "components/common/sections/Services/Service";
import Services from "components/common/sections/Services/Services";
import WhyUs from "components/common/sections/WhyUs/WhyUs";
import Integrations from "components/Integrations/Integrations";
import Layout from "components/layout/Layout/Layout";
import LocomotiveElement from "components/locomotive/LocomotiveElement/LocomotiveElement";
import HomeProjects from "components/pages/home/HomeProjects/HomeProjects";
import MainCloud from "components/rendering/MainCloud/MainCloud";
import RendererWrapper from "components/rendering/RendererWrapper/RendererWrapper";
import { addCloud, useRenderContext } from "contexts/RenderContext";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Circ } from "gsap";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import CSharpIcon from "media/images/icons/other/c-sharp-icon.png";
import GatsbyIcon from "media/images/icons/other/gatsby-icon.png";
import ReactIcon from "media/images/icons/other/react-icon.png";
import UnityIcon from "media/images/icons/other/unity-icon.svg";
import type { PageProps } from "pages";
import { useTheme } from "styled-components";
import { GetSeoData } from "utils/seo-utils";
import Translate from "utils/translation/translation";

const GameDevelopment = ({ location }: PageProps) => {
  const {
    colors: { games, ar, turquoise, yellow }
  } = useTheme();
  const [, dispatchRender] = useRenderContext();
  const rightCloudWrapperRef = useRef<HTMLDivElement>(null);
  const leftCloudWrapperRef = useRef<HTMLDivElement>(null);
  const cloudLeft = {
    name: "VioletBlack",
    wrapper: leftCloudWrapperRef.current
  };
  const cloudRight = {
    name: "PinkViolet",
    wrapper: rightCloudWrapperRef.current
  };

  const integrationsIcons = [
    { image: CSharpIcon, alt: "C Sharp icon" },
    { image: UnityIcon, alt: "Unity" },
    { image: GatsbyIcon, alt: "Gatsby icon" },
    { image: ReactIcon, alt: "React icon" }
  ];

  const designServices: Record<string, Service> = {
    firstService: {
      title: Translate("Story"),
      description: Translate("Common_Text_Story"),
      iconName: "StoryIcon",
      color: ar
    },
    secondService: {
      title: Translate("Art"),
      description: Translate("Common_Text_Art"),
      iconName: "ArtIcon",
      color: turquoise
    },
    thirdService: {
      title: Translate("Marketing"),
      description: Translate("Common_Text_Marketing"),
      iconName: "CreativityIcon",
      color: games
    }
  };

  const developmentServices: Record<string, Service> = {
    firstService: {
      title: Translate("Mechanics"),
      description: Translate("Common_Text_Mechanics"),
      iconName: "AutomationHorizontallyIcon",
      color: yellow
    },
    secondService: {
      title: Translate("Backend"),
      description: Translate("Common_Text_Backend"),
      iconName: "WebDevIcon",
      color: turquoise
    },
    thirdService: {
      title: Translate("Development"),
      description: Translate("Common_Text_Development"),
      iconName: "DesignIcon",
      color: games
    }
  };

  useFirstRenderEffect(() => {
    dispatchRender(addCloud(cloudLeft));
    dispatchRender(addCloud(cloudRight));
  }, []);

  return (
    <>
      <GatsbySeo {...GetSeoData("GameDevelopment")} />
      <Layout location={location}>
        <MainCloud color="Green" variant="MainCloudWrapper" scrollSpeed={-2} />
        <PageTitle {...pageTitleData} />
        <About headerData={aboutHeaderData} />
        <Services headerData={designServicesHeaderData} withoutMargin {...designServices} />
        <Services
          headerData={developmentServicesHeaderData}
          withoutMargin
          isSecondary
          {...developmentServices}
        >
          <LocomotiveElement variant="GameDevelopmentServicesCloud" scrollSpeed={-0.5}>
            <RendererWrapper
              elementRef={rightCloudWrapperRef}
              variant="fill-background-space"
              type="cloud"
              additionalData={"PinkViolet"}
            />
          </LocomotiveElement>
        </Services>
        <ImagesColumn
          imageNameOne="KaykoAndKokosh11"
          imageNameTwo="Narru7"
          imageNameThree="Aloki3"
        />
        <CallToAction />
        <WhyUs>
          <LocomotiveElement variant="GameDevelopmentWhyUsCloud" scrollSpeed={-0.5}>
            <RendererWrapper
              elementRef={leftCloudWrapperRef}
              variant="fill-background-space"
              type="cloud"
              additionalData={"VioletBlack"}
            />
          </LocomotiveElement>
        </WhyUs>
        <Advantages advantages={advantages} />
        <Integrations withoutHeader withButton icons={integrationsIcons} />
        <HomeProjects />
      </Layout>
    </>
  );
};

export default GameDevelopment;

const aboutHeaderData: HeaderGroupProps = {
  headers: [
    {
      text: "GameDevelopment_About_Header",
      variant: "AboutParagraph",
      fontVariant: "about-paragraph",
      id: "about-paragraph",
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0 }
    }
  ]
};

const pageTitleData: PageTitleProps = {
  instantPlayback: true,
  variant: "game-development-title",
  isTitle: true,
  headers: [
    {
      text: "GameDevelopment_Header_Game",
      variant: "GameDevelopmentHeaderGame",
      id: "game-development-game",
      title: true,
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0 },
      horizontalScrollSpeed: 1,
      scrollSpeed: 2
    },
    {
      text: "Page_Header_Development",
      variant: "GameDevelopmentHeaderDevelopment",
      id: "game-development-development",
      title: true,
      animation: { x: 100, opacity: 0, duration: 0.75, delay: 0.75 },
      scrollSpeed: 1.5,
      horizontalScrollSpeed: -0.5
    },
    {
      text: "Page_Header_Develo",
      variant: "GameDevelopmentHeaderDevelo",
      id: "game-development-develo",
      title: true,
      animation: { x: 100, opacity: 0, duration: 0.75, delay: 0.75 },
      scrollSpeed: 1.5,
      horizontalScrollSpeed: -0.5
    },
    {
      text: "Page_Header_Pment",
      variant: "GameDevelopmentHeaderPment",
      id: "game-development-pment",
      title: true,
      animation: { x: 100, opacity: 0, duration: 0.75, delay: 0.75 },
      scrollSpeed: 1.5,
      horizontalScrollSpeed: -0.5
    }
  ],
  subtitle: {
    text: "GameDevelopment_Short_Intro",
    variant: "game-development-subtitle"
  }
};

const designServicesHeaderData: HeaderGroupProps = {
  instantPlayback: false,
  animationPlaybackOffset: -0.75,
  headers: [
    {
      text: "Common_Header_Design",
      variant: "GameDevelopmentDesignServicesHeader",
      id: "game-development-design-services-header",
      animation: { x: 100, opacity: 0, duration: 0.75, delay: 0, ease: Circ.easeOut },
      horizontalScrollSpeed: 0.75
    }
  ]
};

const developmentServicesHeaderData: HeaderGroupProps = {
  instantPlayback: false,
  animationPlaybackOffset: -0.75,
  headers: [
    {
      text: "Common_Header_Development",
      variant: "GameDevelopmentDevelopmentServicesHeader",
      id: "game-development-development-services-header",
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0, ease: Circ.easeOut },
      horizontalScrollSpeed: -0.5
    },
    {
      text: "Common_Header_Develo",
      variant: "GameDevelopmentDeveloServicesHeader",
      id: "game-development-develo-services-header",
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0, ease: Circ.easeOut },
      horizontalScrollSpeed: -0.5
    },
    {
      text: "Common_Header_Pment",
      variant: "GameDevelopmentPmentServicesHeader",
      id: "game-development-pment-services-header",
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0, ease: Circ.easeOut },
      horizontalScrollSpeed: -0.5
    }
  ]
};

const advantages: AdvantagesType = {
  fifth: {
    title: Translate("GameDevelopment_Advantage_Fifth_Title"),
    description: Translate("GameDevelopment_Advantage_Fifth_Description")
  }
};
