import React from "react";

import { useImageDatabaseContext } from "contexts/ImageContext";
import { GatsbyImage } from "gatsby-plugin-image";

import { ImagesColumnItemWrapper } from "./ImagesColumn.styles";

type ItemNumber = 1 | 2 | 3;

interface ImagesColumnItemProps {
  number: ItemNumber;
  imageName: string;
}

export const ImagesColumnItem = ({ number, imageName }: ImagesColumnItemProps) => {
  const [imageDatabase] = useImageDatabaseContext();
  const image = imageDatabase[imageName].childImageSharp.gatsbyImageData;

  return (
    <ImagesColumnItemWrapper number={number}>
      <GatsbyImage image={image} draggable={false} alt="Photo" />
    </ImagesColumnItemWrapper>
  );
};
