import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import styled, { css } from "styled-components";

export const ImagesColumnWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;

  ${CalculateFromHeight("margin-top", { mobile: 17, tablet: 15, desktop: 13 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 17, tablet: 15, desktop: 13 })}
  ${CalculateFromWidth("margin-left", { desktop: 5 })}
`;

export const ImagesColumnItemWrapper = styled.div<{ number?: number }>`
  position: relative;
  max-width: 980px;
  grid-column: 1 / span 8;
  z-index: 1;

  ${({ number }) =>
    number === 2 &&
    css`
      grid-column: 4 / span 8;
      margin-top: -80px;
      z-index: 2;
    `};

  ${({ number }) =>
    number === 3 &&
    css`
      margin-top: -80px;
      z-index: 3;
    `};

  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 2 / span 7;

    ${({ number }) =>
      number === 2 &&
      css`
        grid-column: 5 / span 7;
        margin-top: -30px;
      `};

    ${({ number }) =>
      number === 3 &&
      css`
        margin-top: -30px;
      `};
  }

  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 9;

    ${({ number }) =>
      number === 2 &&
      css`
        grid-column: 3 / span 9;
        margin-top: -10px;
      `};

    ${({ number }) =>
      number === 3 &&
      css`
        margin-top: -10px;
      `};
  }
`;
